<template>
  <div class="cadastro-perfil">
    <div class="content">
      <div class="cards-cadastro">
        <v-card class="info-cadastro card">
          <div class="title-info">
            <span>
              Cadastro <span class="color">{{ typeProfile }} </span>
            </span>
          </div>
          <div class="sub-title">
            <span>
              Você está realizando um cadastro de
              {{ typeProfile.toLowerCase() }} à Kristal Connect
            </span>
            <span> Preencha todas as informações com bastante atenção! </span>
          </div>
        </v-card>

        <v-card class="form-content card">
          <v-form ref="form" v-model="valid" lazy-validation>
            <div class="informacoes">
              <div class="descriptions">
                <span class="principal"> Informações Pessoais </span>
                <span class="secundario">
                  Informações Pessoais para cadastro
                </span>
              </div>

              <div class="inputs-infos">
                <div class="name">
                  <span>Nome ou Razão Social</span>
                  <v-text-field
                    v-model="user.name"
                    :rules="[
                      (v) =>
                        !!v || 'Por favor, insira seu nome ou razão social',
                    ]"
                    color="secondary"
                    required
                    outlined
                    rounded
                    class="input"
                  />
                </div>
                <div class="cpf">
                  <span>CPF ou CNPJ</span>
                  <v-text-field
                    v-model="user.document"
                    :rules="[
                      (v) => !!v || 'Por favor, insira seu cpf ou cnpj',
                      rules.documentMinCpf,
                    ]"
                    color="secondary"
                    required
                    outlined
                    rounded
                    class="input"
                  />
                </div>
              </div>
            </div>

            <div class="informacoes">
              <div class="descriptions">
                <span class="principal"> Informações de Login </span>
                <span class="secundario">
                  Seu e-mail para login na plataforma de afiliado, e sua senha.
                </span>
              </div>

              <div class="inputs-infos">
                <div class="email">
                  <span>E-mail</span>
                  <v-text-field
                    v-model="user.email"
                    :rules="[(v) => !!v || 'Por favor, insira seu E-mail']"
                    color="secondary"
                    required
                    outlined
                    rounded
                    class="input"
                  />
                </div>
                <div class="senha">
                  <span>Senha</span>
                  <v-text-field
                    v-model="user.password"
                    placeholder="********"
                    :rules="passwordRules"
                    color="secondary"
                    required
                    outlined
                    rounded
                    class="input"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    @click:append="show = !show"
                  />
                </div>

                <div class="confirmacao-senha">
                  <span>Repita sua Senha</span>
                  <v-text-field
                    v-model="confirmedPassword"
                    placeholder="********"
                    :rules="passwordRules"
                    color="secondary"
                    required
                    outlined
                    rounded
                    class="input"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    @click:append="show = !show"
                  />
                </div>
              </div>
            </div>
          </v-form>

          <div class="condicoes">
            <input
              type="checkbox"
              name="s"
              id="checkBox"
              class="checkBox"
              v-model="user.acceptTerms"
              @click="!user.acceptTerms"
            />
            <span>
              Eu li e concordo com os
              <span class="termos-condicoes">Termos e Condições</span>
            </span>
          </div>

          <div class="btn-nova-conta">
            <v-btn
              v-if="!success"
              :disabled="!valid"
              large
              rounded
              color="info"
              @click="createNewAccount"
              :loading="loading"
              class="my-4 btn-login py-3"
            >
              <p style="color: white; margin-bottom: 0px">Criar nova conta</p>
            </v-btn>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../../services/ApiService";
import Utils from "../../../Utils/ValidateCpnjCpf";
export default {
  data() {
    return {
      apiService: new ApiService(),
      valid: true,
      show: false,
      success: false,
      typeProfileId: "",
      typeProfile: "",
      loading: false,
      termosCondicoesLidos: false,
      passwordRules: [(v) => !!v || "Senha requerida"],
      confirmedPassword: "",
      user: {
        name: "",
        email: "",
        document: "",
        address: "",
        userProfile: "",
        password: "",
        acceptTerms: false,
      },
      rules: {
        documentMinCpf: (value) =>
          this.validarDocumento(value) || "CPF ou CNPJ inválido",
      },
    };
  },
  created() {
    this.typeProfileId = this.$store.getters.$profileType.id;
    if (this.$store.getters.$profileType.id == 1) {
      this.typeProfile = "Assessor";
    }
    if (this.$store.getters.$profileType.id == 0) {
      this.typeProfile = "Investidor";
    }

    if (this.$store.getters.$profileType.id == undefined || null) {
        this.$router.push({ name: "escolhaPerfil" });
    }
  },
  methods: {
    validarDocumento(value) {
      let size = value.replaceAll(".", "").replaceAll("-", "").length;

      if (size > 11) {
        return Utils.validarCNPJ(value);
      }

      return Utils.validarCpf(value);
    },
    validate() {
      return this.$refs.form.validate();
    },
    async preLogin() {
      try {
        let req = await this.apiService.post("User/token", {
          Email: this.user.email,
          Password: this.user.password,
        });

        let res = await req;
        let validTo = req.content.validTo;
        let userLogged = JSON.stringify(res.content.user);
        sessionStorage.setItem("token", res.content.token);
        this.$store.commit("SET_LOGGED_USER", userLogged);
        this.$store.commit("SET_TOKEN_USER_SELECTED", userLogged.userId);
        this.$store.commit("SET_USER_SELECTED", userLogged);
        this.$store.commit("SET_EXPIRES_TOKEN", validTo);

        if (res.content.statusCode == 200) {
          return true;
        }
      } catch (error) {
        this.$toast.error("Erro ao realizar pré login");
      }
    },
    async createNewAccount() {
      if (!this.senhasIguais) this.$toast.error("Senhas divergentes");
      if (!this.user.acceptTerms) this.$toast.error("Por favor, leia os termos e condições");
      if (!Utils.validarEmail(this.user.email)) this.$toast.error("Por favor, insira um e-mail válido");

      if ( this.user.acceptTerms && this.senhasIguais && Utils.validarEmail(this.user.email)) {
        this.user.userProfile = this.typeProfileId;

        try {
          if (this.validate()) {
            this.loading = true;
            let unformattedDocument = this.user.document.replaceAll(".", "").replaceAll("-", "").replaceAll("/", "");
            this.user.document = unformattedDocument;
            let req = await this.apiService.post("user/register", this.user);

            if (req.statusCode === 200) {
              this.$store.dispatch("PUT_PRE_USER_REGISTER", req.content);

             this.preLogin() ?  this.$router.push({ name: "selecaoAssinatura" }) : ''
            }
          }
        } catch (error) {

          //error.body.message mensagem de erro, que já está aparecendo
          this.$toast.error("Erro ao cadastrar usuário");
          this.loading = false;
        }
      }
    },
  },
  computed: {
    senhasIguais() {
      return this.user.password === this.confirmedPassword;
    },
  },
};
</script>

<style  scoped>
.cadastro-perfil {
  min-height: 100vh !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem 0;
}
.content {
  width: 80%;
}

.info-cadastro {
  border-top: 15px solid #f3a03c;
  border-radius: 0 0 15px 15px;
}
.info-cadastro .title-info {
  font-size: 1.4em;
  font-weight: 600;
}
.info-cadastro .title-info .color {
  color: #f3a03c;
}
.info-cadastro .sub-title {
  font-weight: 300;
  font-size: 0.9em;
}
.info-cadastro .sub-title span {
  display: block;
  margin: 0.3rem 0;
}

/* FORMULARIO */
.card {
  padding: 1.5rem 2rem;
  margin: 1rem 0;
}
.form-content {
  border-radius: 15px;
}

/* INFORMACOES PESSOAIS  E LOGIN*/
.informacoes .descriptions span {
  display: block;
  margin: 0.2rem 0;
}
.informacoes .descriptions .principal {
  font-size: 1.2em;
  font-weight: 600;
}
.informacoes .descriptions .secundario {
  font-size: 0.8em;
  line-height: 124.4%;
  color: #9c9c9c;
}
.inputs-infos {
  margin: 1.5rem 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.inputs-infos > div {
  flex: 0 1 400px;
  width: 100%;
}
.inputs-infos > div span {
  margin: 0.5rem;
  padding: 0.2rem 0;
  display: block;
  line-height: 124.4%;
  color: #9c9c9c;
  font-size: 1.2em;
}

/* FIM INFORMACOES PESSOAIS E LOGIN*/

.condicoes {
  margin-top: 0;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.termos-condicoes {
  /* border-bottom: 1px solid; */
  cursor: pointer;
}
.condicoes .checkBox {
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
  transform: scale(1.5);
  margin: 0 0.5rem;
}

.btn-nova-conta {
  width: 70%;
  margin: 0 auto;
}
.btn-login {
  margin: 0 auto;
  width: 100%;
  background-color: var(--principal-azul) !important;
  padding: 0.7rem 0 !important;
  font-weight: 700;
}
</style>