var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cadastro-perfil" }, [
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "cards-cadastro" },
        [
          _c("v-card", { staticClass: "info-cadastro card" }, [
            _c("div", { staticClass: "title-info" }, [
              _c("span", [
                _vm._v(" Cadastro "),
                _c("span", { staticClass: "color" }, [
                  _vm._v(_vm._s(_vm.typeProfile) + " "),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "sub-title" }, [
              _c("span", [
                _vm._v(
                  " Você está realizando um cadastro de " +
                    _vm._s(_vm.typeProfile.toLowerCase()) +
                    " à Kristal Connect "
                ),
              ]),
              _c("span", [
                _vm._v(" Preencha todas as informações com bastante atenção! "),
              ]),
            ]),
          ]),
          _c(
            "v-card",
            { staticClass: "form-content card" },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c("div", { staticClass: "informacoes" }, [
                    _c("div", { staticClass: "descriptions" }, [
                      _c("span", { staticClass: "principal" }, [
                        _vm._v(" Informações Pessoais "),
                      ]),
                      _c("span", { staticClass: "secundario" }, [
                        _vm._v(" Informações Pessoais para cadastro "),
                      ]),
                    ]),
                    _c("div", { staticClass: "inputs-infos" }, [
                      _c(
                        "div",
                        { staticClass: "name" },
                        [
                          _c("span", [_vm._v("Nome ou Razão Social")]),
                          _c("v-text-field", {
                            staticClass: "input",
                            attrs: {
                              rules: [
                                (v) =>
                                  !!v ||
                                  "Por favor, insira seu nome ou razão social",
                              ],
                              color: "secondary",
                              required: "",
                              outlined: "",
                              rounded: "",
                            },
                            model: {
                              value: _vm.user.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "name", $$v)
                              },
                              expression: "user.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "cpf" },
                        [
                          _c("span", [_vm._v("CPF ou CNPJ")]),
                          _c("v-text-field", {
                            staticClass: "input",
                            attrs: {
                              rules: [
                                (v) =>
                                  !!v || "Por favor, insira seu cpf ou cnpj",
                                _vm.rules.documentMinCpf,
                              ],
                              color: "secondary",
                              required: "",
                              outlined: "",
                              rounded: "",
                            },
                            model: {
                              value: _vm.user.document,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "document", $$v)
                              },
                              expression: "user.document",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "informacoes" }, [
                    _c("div", { staticClass: "descriptions" }, [
                      _c("span", { staticClass: "principal" }, [
                        _vm._v(" Informações de Login "),
                      ]),
                      _c("span", { staticClass: "secundario" }, [
                        _vm._v(
                          " Seu e-mail para login na plataforma de afiliado, e sua senha. "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "inputs-infos" }, [
                      _c(
                        "div",
                        { staticClass: "email" },
                        [
                          _c("span", [_vm._v("E-mail")]),
                          _c("v-text-field", {
                            staticClass: "input",
                            attrs: {
                              rules: [
                                (v) => !!v || "Por favor, insira seu E-mail",
                              ],
                              color: "secondary",
                              required: "",
                              outlined: "",
                              rounded: "",
                            },
                            model: {
                              value: _vm.user.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "email", $$v)
                              },
                              expression: "user.email",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "senha" },
                        [
                          _c("span", [_vm._v("Senha")]),
                          _c("v-text-field", {
                            staticClass: "input",
                            attrs: {
                              placeholder: "********",
                              rules: _vm.passwordRules,
                              color: "secondary",
                              required: "",
                              outlined: "",
                              rounded: "",
                              "append-icon": _vm.show
                                ? "mdi-eye"
                                : "mdi-eye-off",
                              type: _vm.show ? "text" : "password",
                            },
                            on: {
                              "click:append": function ($event) {
                                _vm.show = !_vm.show
                              },
                            },
                            model: {
                              value: _vm.user.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "password", $$v)
                              },
                              expression: "user.password",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "confirmacao-senha" },
                        [
                          _c("span", [_vm._v("Repita sua Senha")]),
                          _c("v-text-field", {
                            staticClass: "input",
                            attrs: {
                              placeholder: "********",
                              rules: _vm.passwordRules,
                              color: "secondary",
                              required: "",
                              outlined: "",
                              rounded: "",
                              "append-icon": _vm.show
                                ? "mdi-eye"
                                : "mdi-eye-off",
                              type: _vm.show ? "text" : "password",
                            },
                            on: {
                              "click:append": function ($event) {
                                _vm.show = !_vm.show
                              },
                            },
                            model: {
                              value: _vm.confirmedPassword,
                              callback: function ($$v) {
                                _vm.confirmedPassword = $$v
                              },
                              expression: "confirmedPassword",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
              _c("div", { staticClass: "condicoes" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.acceptTerms,
                      expression: "user.acceptTerms",
                    },
                  ],
                  staticClass: "checkBox",
                  attrs: { type: "checkbox", name: "s", id: "checkBox" },
                  domProps: {
                    checked: Array.isArray(_vm.user.acceptTerms)
                      ? _vm._i(_vm.user.acceptTerms, null) > -1
                      : _vm.user.acceptTerms,
                  },
                  on: {
                    click: function ($event) {
                      !_vm.user.acceptTerms
                    },
                    change: function ($event) {
                      var $$a = _vm.user.acceptTerms,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(_vm.user, "acceptTerms", $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.user,
                              "acceptTerms",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.user, "acceptTerms", $$c)
                      }
                    },
                  },
                }),
                _c("span", [
                  _vm._v(" Eu li e concordo com os "),
                  _c("span", { staticClass: "termos-condicoes" }, [
                    _vm._v("Termos e Condições"),
                  ]),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "btn-nova-conta" },
                [
                  !_vm.success
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "my-4 btn-login py-3",
                          attrs: {
                            disabled: !_vm.valid,
                            large: "",
                            rounded: "",
                            color: "info",
                            loading: _vm.loading,
                          },
                          on: { click: _vm.createNewAccount },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticStyle: {
                                color: "white",
                                "margin-bottom": "0px",
                              },
                            },
                            [_vm._v("Criar nova conta")]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }